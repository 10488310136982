<template>
  <div class="gtm-container">
    <div class="heading relative mt-24 md:mt-0">
      <div
        class="timer absolute right-0 bottom-0 text-black"
        v-if="started && !finished"
      >
        <Counter :displayTime="displayTime" />
      </div>
    </div>

    <div
      class="start-text text-center bg-nColorBlue mt-8 rounded-xl"
      v-if="!flags.has_played && !started"
    >
      <h1 class="text-9xl font-bold pt-8">Lets Start</h1>
      <button
        class="bg-nButtonGreen px-20 rounded-xl py-4 my-8 text-lg"
        @click="startGTM()"
      >
        Start
      </button>
    </div>
    <div class="gtm-exp" v-else-if="started && !finished">
      <div
        class="movie-show relative"
        v-for="(movie, index) in movies"
        :key="movie.movie_id"
      >
        <h3
          v-if="movieNumber === index"
          class="absolute -top-7 md:top-4 left-0 md:left-5 text-xl md:text-2xl font-semibold md:font-bold"
        >
          {{ movieNumber + 1 }} / {{ movies.length }}
        </h3>
        <GTMMovie
          @correct="addResult"
          @skip="skipMovie"
          @endGTM="endGTM"
          v-if="movieNumber === index"
          :movie="movie"
          :solution="decodedMovieName[movie.movie_id]"
          :userInputInit="userInput[movie.movie_id]"
        />
      </div>
    </div>

    <div v-else class="gtm-end mt-4 md:mt-8">
      <div
        class="end-header text-center bg-nButtonGreen py-8 md:py-16 rounded-xl relative"
      >
        <div class="absolute top-0 left-0">
          <lottie-animation
            path="lotties/confetti3.json"
            :loop="true"
            :autoPlay="true"
            :speed="1"
            :height="300"
          />
        </div>
        <div class="absolute top-0 right-0">
          <lottie-animation
            path="lotties/confetti3.json"
            :loop="true"
            :autoPlay="true"
            :speed="1"
            :height="300"
          />
        </div>
        <h1 class="text-9xl font-bold">You did it!</h1>
        <p
          class="text-lg md:text-xl font-semibold mt-4 text-gray-200"
          v-if="indiScore"
        >
          You guessed
          {{ indiScore.score }}/{{ indiScore.total_questions }} movies correctly
          in {{ indiScore.total_duration }}s
        </p>
      </div>
      <div>
        <Leaderboard
          v-if="leaderboard"
          :leaderboardContent="leaderboard"
          :showDuration="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Counter from "@/components/Experiences/GTM/Counter.vue";
import GTMMovie from "@/components/Experiences/GTM/GTMMovie.vue";
import Leaderboard from "./Leaderboard.vue";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

export default {
  name: "GTMContainer",
  props: ["expname", "movies", "leaderBoard", "flags"],
  components: {
    GTMMovie,
    Counter,
    Leaderboard,
    LottieAnimation,
  },
  created() {
    // if exp is LIVE or user starts playing when its LIVE.
    // if user has played the exp, info.movies does not exist
    if (this.movies) {
      this.movies.forEach((movie) => {
        let wordStringArr = [];
        this.userInput[movie.movie_id] = movie.name.map((word) => {
          let wordString = word
            .map((letter) => String.fromCharCode(letter))
            .join("");
          wordStringArr.push(wordString);
          return new Array(word.length);
        });
        this.decodedMovieName[movie.movie_id] = wordStringArr.join("");
      });
      let totalSeconds = this.movies.length * this.timePerQ;
      this.displayTime.minute = Math.floor(totalSeconds / 60);
      this.displayTime.sec = totalSeconds % 60;
      this.totalTime.minute = this.displayTime.minute;
      this.totalTime.sec = this.displayTime.sec;
    } else {
      return;
    }
  },
  data() {
    return {
      userInput: {},
      decodedMovieName: {},
      movieNumber: 0,
      timePerQ: 10,
      result: [],
      finished: false,
      started: false,
      totalTime: {
        sec: 0,
        minute: 0,
      },
      displayTime: {
        sec: 0,
        minute: 0,
      },
      indiScore: null,
      timer: null,
      leaderboard: null,
    };
  },
  methods: {
    startGTM() {
      this.started = true;
      this.countDown();
    },
    skipMovie(answer) {
      answer.time_taken = this.calcTimeTaken();
      this.result.push(answer);
      this.nextMovie();
    },
    addResult(answer) {
      answer.time_taken = this.calcTimeTaken();
      this.result.push(answer);
      this.nextMovie();
    },
    endGTM(answer = null) {
      clearInterval(this.timer);
      if (answer) {
        answer.time_taken = this.calcTimeTaken();
        this.result.push(answer);
      }
      console.log(this.result);
      let stats = this.calculateRandomStats();
      this.indiScore = stats.filter((emp) => emp.employee_id === 20)[0];
      this.leaderboard = stats;
      this.finished = true;
    },
    calculateRandomStats() {
      var empId1 = this.getRandomInt(1, 2000);
      var empId2 = this.getRandomInt(1, 2000);
      var empId3 = this.getRandomInt(1, 2000);
      var empId4 = this.getRandomInt(1, 2000);
      var empId5 = this.getRandomInt(1, 2000);
      let indiScore = this.calculateIndiScore();
      return [
        {
          employee_id: empId1,
          employee_name: "Player" + empId1.toString(),
          first_name: "Player",
          last_name: empId1.toString(),
          score: this.getRandomInt(0, 20),
          total_questions: "20",
          total_duration: this.getRandomInt(30, 180),
        },
        {
          employee_id: empId2,
          employee_name: "Player" + empId2.toString(),
          first_name: "Player",
          last_name: empId2.toString(),
          score: this.getRandomInt(0, 20),
          total_questions: "20",
          total_duration: this.getRandomInt(30, 180),
        },
        {
          employee_id: empId3,
          employee_name: "Player" + empId3.toString(),
          first_name: "Player",
          last_name: empId3.toString(),
          score: this.getRandomInt(0, 20),
          total_questions: "20",
          total_duration: this.getRandomInt(30, 180),
        },
        {
          employee_id: empId4,
          employee_name: "Player" + empId4.toString(),
          first_name: "Player",
          last_name: empId4.toString(),
          score: this.getRandomInt(0, 20),
          total_questions: "20",
          total_duration: this.getRandomInt(30, 180),
        },
        {
          employee_id: empId5,
          employee_name: "Player" + empId5.toString(),
          first_name: "Player",
          last_name: empId5.toString(),
          score: this.getRandomInt(0, 20),
          total_questions: "20",
          total_duration: this.getRandomInt(30, 180),
        },
        {
          employee_id: 20,
          employee_name: "Player20",
          first_name: "Player",
          last_name: "20",
          score: indiScore.score,
          total_questions: "20",
          total_duration: indiScore.total_duration,
        },
      ];
    },
    calculateIndiScore() {
      let response = {
        score: 0,
        total_duration: 0,
      };
      for (var i = 0; i < this.result.length; i++) {
        response.score += parseInt(this.result[i].guess_correct);
        response.total_duration += parseInt(this.result[i].time_taken);
      }
      return response;
    },
    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min) + min);
    },
    nextMovie() {
      if (this.movieNumber < this.movies.length - 1) {
        this.movieNumber++;
      } else {
        this.endGTM();
      }
    },
    countDown() {
      this.timer = setInterval(() => {
        if (this.displayTime.minute == 0 && this.displayTime.sec == 0) {
          clearInterval(this.timer);
          this.endGTM();
        } else {
          if (this.displayTime.sec == 0) {
            this.displayTime.sec = 59;
            this.displayTime.minute--;
          } else {
            this.displayTime.sec--;
          }
        }
      }, 1000);
    },
    calcTimeTaken() {
      let totalSeconds = this.totalTime.minute * 60 + this.totalTime.sec;
      let currentSeconds = this.displayTime.minute * 60 + this.displayTime.sec;
      this.totalTime.minute = this.displayTime.minute;
      this.totalTime.sec = this.displayTime.sec;
      return (totalSeconds - currentSeconds).toString();
    },
    getHeaderText() {
      return this.expname;
    },
  },
  computed: {
    leaderboardContent() {
      if (this.leaderBoard) return this.leaderBoard;
      return null;
    },
  },
};
</script>

<style scoped></style>
